import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Développeur web Magento e-commerce à Nantes" />
    <section>
      <h1 style={{textAlign: `center`}}>
        Développeur web Magento e-commerce à Nantes
      </h1>
      <article id="post-2" class="post-2 page type-page">
        <div class="home-top">
          <p style={{ textAlign: `center`}}>
            <span class="white">Bonjour</span> à tous, je suis <span class="white"><strong>développeur web / web designer</strong>.</span>
          </p>
          <div class="typed-home" style={{textAlign: `center`}}>
            Originaire <strong>d’Amiens</strong>, je vis actuellement à <strong>Nantes</strong>, France où je travaille en tant que développeur frontend.
          </div>
          <p style={{textAlign: `center`}}>Passionné par le web et son évolution, je suis spécialisé dans<strong> la solution e-commerce <span class="white">Magento</span></strong>.</p>
          <p style={{textAlign: `center`}}>
            <Link class="home-link-about" to="/a-propos/">En savoir plus</Link>
            <a class="home-link-contact" title="Contact" href="mailto:contact@laurentlepee.com">Contactez-moi</a>
          </p>
        </div>
        <div class="home-middle">
          <h2 style={{ textAlign: `center`}}>&nbsp;Ce que je peux vous apporter</h2>
          <div>
            <span class="icon-Magento">&nbsp;</span><p></p>
            <h3>Développeur Magento</h3>
            <p>Magento est la solution e-commerce la plus utilisée au monde. Expert de cette solution, je peux déployer votre site de vente en ligne répondant à vos attentes. Besoin de mettre à jour votre site e-commerce ou d’un développement spécifique, je peux répondre à vos besoins.</p>
          </div>
          <div>
            <span class="icon-wordpress">&nbsp;</span><p></p>
            <h3>Développeur WordPress</h3>
            <p>Besoin d’un site vitrine ? WordPress est le CMS qu’il vous faut. Rapide à prendre en main, il permet de mettre en place rapidement un site vous correspondant. Hautement modulaire, WordPress offre des perspectives infinies en terme de développement.</p>
          </div>
          <div>
            <span class="icon-css3">&nbsp;</span><p></p>
            <h3>Web designer</h3>
            <p>Votre site à besoin d’un coup de jeune ? Spécialiste des languages HTML5, CSS3 et jQuery, je crée des sites adaptatifs aux plateformes mobiles (responsive design), respectant les standards W3C et normes d’accessibilité. Tout en optimisant les performances et l’ergonomie.</p>
          </div>
          <div>
            <span class="icon-file-code-o">&nbsp;</span><p></p>
            <h3>Optimisation SEO</h3>
            <p>Pour être visible sur le web, un bon référencement est nécessaire. Grace à différents outils d’optimisation et d’analyse, votre site remonte sur les différents moteurs de recherche pour toucher plus de monde.</p>
          </div>
        </div>
      </article>
    </section>  
  </Layout>
)

export default IndexPage
